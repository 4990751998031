const print = (element, noPrintSelector = "[data-no-print]") => {
  if (typeof window === "undefined") {
    return;
  }
  const isFirefox = typeof InstallTrigger !== "undefined";
  const isIE =
    navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode;
  const isEdge = !isIE && !!window.StyleMedia;
  const isChrome = !!window.chrome;
  const printFrame = document.createElement("IFRAME");
  printFrame.title = document.title;
  printFrame.setAttribute("aria-hidden", "true");
  const frameStyles = isFirefox
    ? "width: 1px; height: 100px; position: fixed; left: 0; top: 0; opacity: 0; border-width: 0; margin: 0; padding: 0"
    : "visibility: hidden; height: 0; width: 0; position: absolute; border: 0";
  printFrame.setAttribute("style", frameStyles);

  let styles = document.head.querySelectorAll("style, link[rel=stylesheet]");
  const printHtml = element.cloneNode(true);
  Array.from(printHtml.querySelectorAll(noPrintSelector)).forEach((elem) =>
    elem.remove()
  );

  styles = Array.from(styles).map((s) => s.cloneNode(true));
  document.body.append(printFrame);
  const doc = printFrame.contentDocument;
  doc.head.append(...styles);
  doc.body.setAttribute("style", "margin-left: 2cm;");
  doc.body.append(printHtml);

  setTimeout(() => {
    try {
      printFrame.focus();

      // If Edge or IE, try catch with execCommand
      if (isEdge || isIE) {
        try {
          printFrame.contentWindow.document.execCommand("print", false, null);
        } catch (e) {
          printFrame.contentWindow.print();
        }
      } else {
        // Other browsers
        printFrame.contentWindow.print();
      }
    } catch (error) {
      // params.onError(error);
    } finally {
      if (isFirefox) {
        // Move the iframe element off-screen and make it invisible
        printFrame.style.visibility = "hidden";
        printFrame.style.left = "-1px";
      }
      // Run onPrintDialogClose callback
      let event = "mouseover touchstart";

      if (isChrome || isFirefox) {
        // Ps.: Firefox will require an extra click in the document to fire the focus event.
        event = "focus";
      }

      const handler = () => {
        // Make sure the event only happens once.
        window.removeEventListener(event, handler);
        if (printFrame) {
          printFrame.remove();
        }
      };

      window.addEventListener(event, handler);
    }
  }, 3000);
};

export default print;
