const ldJsonBreadCrumb = (breadCrumbPaths = []) =>
  JSON.stringify(
    breadCrumbPaths.map((itemListElement) => ({
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      itemListElement: itemListElement.map((item, i) => ({
        "@type": "ListItem",
        position: i + 1,
        name: item.name,
        item: item.item,
      })),
    }))
  );

export default ldJsonBreadCrumb;
