import * as React from "react";
import { withTheme, css } from "@delight-js/react";

import print from "../helpers/print";
import Hero from "../components/Hero";
import ContentBox from "../components/layout/ContentBox";
import TeaserSlider from "../components/TeaserSlider";
import Button from "../components/Button";
import Grid from "../components/layout/Grid";
import Row from "../components/layout/Row";
import Cell from "../components/layout/Cell";
import Chip from "../components/Chip";
import SharingBar from "../components/SharingBar";
import Link from "../components/Link";
import NewsletterBlock from "../components/NewsletterBlock";
import {
  Caption,
  P2,
  H3,
  H6,
  Subtitle2,
  Overline,
  List,
} from "../components/texts";

import singularPlural from "../helpers/singularPlural";
import Icon from "../components/Icon";
import SpaceBetween from "../components/layout/SpaceBetween";
import ParseMarkdown, { withTopMargin } from "../components/ParseMarkdown";
import ldJsonRecipe, { dataToRecipeData } from "../helpers/ldJsonRecipe";
import ldJsonBreadCrumb from "../helpers/ldJsonBreadCrumb";

const recipeMarkdownComponents = {
  p: withTopMargin(({ node, ...props }) => <P2 {...props} />),
  ul: withTopMargin(
    withTheme(({ theme, node, depth, ordered, ...props }) => (
      <List
        css={css`
      $textBody2;
    `(theme)}
        {...props}
      />
    ))
  ),
};

const ContentRecipe = withTheme(
  ({
    theme,
    images,
    headline,
    persons,
    time,
    headlineLevel,
    translations,
    mealComponent,
    ...props
  }) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const [printing, setPrinting] = React.useState(false);

    React.useEffect(() => {
      let timeout = null;
      if (printing) {
        timeout = setTimeout(() => {
          setPrinting(false);
        }, 3000);
      }
      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [printing]);

    const handlePrint = () => {
      setPrinting(true);
      print(document.getElementById("recipe"));
    };
    return (
      <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }} {...props}>
        <Grid
          css={css`
            $py14;
            color: $color-text2;
            background-color: $color-bg2;
          `(theme)}
          id="recipe"
        >
          <Row>
            <Cell
              span={{ default: 8 / 10, x2l: 6 / 10 }}
              offset={{ default: 1 / 10, x2l: 2 / 10 }}
            >
              <Overline
                css={css`
                  $mb1;
                  color: currentColor;
                `(theme)}
              >
                {translations.hero.overline}
              </Overline>
              {!!headline && (
                <H3
                  as={headlineLevel || "h2"}
                  css={css`
                    color: currentColor;
                  `}
                >
                  {headline}
                </H3>
              )}
              {(typeof time !== "undefined" ||
                typeof persons !== "undefined") && (
                <Subtitle2 css={css`$mt2;`(theme)}>
                  {typeof persons !== "undefined" && (
                    <span
                      css={css`
                        $mr4;
                        display: inline-flex;
                        align-items: center;
                      `(theme)}
                    >
                      <Icon
                        type="persons"
                        css={css`
                          $mr1;
                          margin-top: -2px;
                        `(theme)}
                      />
                      {persons}{" "}
                      {singularPlural(persons, ...translations.person)}
                    </span>
                  )}
                  {typeof time !== "undefined" && (
                    <span
                      css={css`
                        $mr4;
                        display: inline-flex;
                        align-items: center;
                      `(theme)}
                    >
                      <Icon
                        type="clock"
                        css={css`
                          $mr1;
                          margin-top: -2px;
                        `(theme)}
                      />
                      {!!hours &&
                        `${hours} ${singularPlural(
                          hours,
                          ...translations.hour
                        )} `}
                      {!!minutes &&
                        `${minutes} ${singularPlural(
                          minutes,
                          ...translations.minute
                        )}`}
                    </span>
                  )}
                </Subtitle2>
              )}
              {!!mealComponent &&
                mealComponent.map((m, index) => (
                  <div css={css`$mt10;`(theme)} key={`${m.name}-${index}`}>
                    <H6
                      css={css`
                        color: currentColor;
                      `}
                    >
                      {m.name}
                    </H6>
                    <Caption
                      as="ul"
                      css={css`
                        $mt2;
                        list-style: none;
                        max-width: 500px;
                        @media (max-width: 639.999px) {
                          columns: 1 !important;
                        }
                      `(theme)}
                      style={{
                        columns: m.ingredients.length >= 6 ? 2 : 1,
                      }}
                    >
                      {m.ingredients.map((i) => (
                        <li key={i}>{i}</li>
                      ))}
                    </Caption>
                    <div css={css`$mt2;`(theme)}>
                      <ParseMarkdown
                        markdown={m.cooking}
                        components={recipeMarkdownComponents}
                      />
                    </div>
                  </div>
                ))}
              <div
                css={css`
                  $mt10;
                  text-align: center;
                `(theme)}
                data-no-print
              >
                <Button
                  css={css`
                    color: currentColor;
                    border-color: currentColor;
                  `}
                  onClick={handlePrint}
                  disabled={printing}
                >
                  {translations.print}
                </Button>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Cell>
    );
  }
);

const ArticleTemplate = withTheme(({ theme, ...props }) => {
  const { pageData, teaserItems, translations, baseUrl } = props.pageContext;
  const {
    hero,
    categories = [],
    content,
    breadCrumbs,
    fullUrl,
    containsAdvertisingText,
    locale,
    more,
    articleType,
    meta,
  } = pageData;

  let ldJson = "";
  if (articleType === "recipe") {
    const recipe = content.find(
      (block) => block.strapi_component === "content-blocks.recipe"
    );
    const galleries = content.filter(
      (block) => block.strapi_component === "content-blocks.gallery"
    );
    const videos = content.filter(
      (block) => block.strapi_component === "content-blocks.video"
    );
    ldJson = ldJsonRecipe(
      dataToRecipeData({
        meta,
        categories,
        galleries,
        recipe,
        baseUrl,
        videos,
      })
    );
  }
  const withTranslation = (Component) => (props) => (
    <Component translations={translations} {...props} />
  );
  return (
    <React.Fragment>
      {!!hero && (
        <Hero
          overline={hero.overline}
          headline={hero.headline}
          subline={
            hero.subline ||
            categories.map((item, index, { length }) => (
              <React.Fragment key={index}>
                {item.name.replace(/ /g, "\u00a0") +
                  (length - 1 - index > 0 ? "\u00a0| " : "")}
              </React.Fragment>
            ))
          }
          image={hero?.image?.localFile}
          metaline={containsAdvertisingText}
          sharingUrl={fullUrl}
        />
      )}
      <ContentBox
        content={content}
        addBlocks={{
          "content-blocks.recipe": withTranslation(ContentRecipe),
        }}
        css={css`$mt20; $mb26;`(theme)}
        locale={locale}
      />
      {!!breadCrumbs && !!breadCrumbs.length && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: ldJsonBreadCrumb(breadCrumbs) }}
        />
      )}
      {!!ldJson.length && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: ldJson }}
        />
      )}
      <SharingBar url={fullUrl} css={css`$mb26;`(theme)} />
      {!!teaserItems && !!teaserItems.length && (
        <TeaserSlider
          items={teaserItems}
          headline={more}
          subline={
            <SpaceBetween>
              {categories.map((item, index) => (
                <Chip
                  key={`${item.name}-${(!!item.isActive).toString()}`}
                  as={Link}
                  to={item.url}
                  secondary={!item.isPrimary}
                  css={css`
                    position: relative;
                  `}
                >
                  {item.name}
                </Chip>
              ))}
            </SpaceBetween>
          }
          css={css`$mb26;`(theme)}
        />
      )}
      <NewsletterBlock css={css`$mb26;`(theme)} locale={locale} />
    </React.Fragment>
  );
});

export default ArticleTemplate;
