const dataToRecipeData = ({
  meta,
  categories = [],
  galleries = [],
  recipe = {},
  baseUrl,
  videos = [],
} = {}) => {
  const image = [];
  if (meta?.sharingImageTwitter?.src) {
    image.push(meta?.sharingImageTwitter?.src);
  }
  galleries.forEach((g) =>
    g.gallery?.rows.forEach((r) =>
      r.images.forEach((i) => {
        image.push(
          `${baseUrl}${i?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src}`
        );
      })
    )
  );
  const video = videos
    .filter((v) => v.videoId || v.video?.videoId)
    .map((v) => {
      const actualData = v.videoId ? v : v.video;
      const actualThumbnailPath = (actualData?.cover || actualData?.customCover)
        ?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src;
      return {
        ...(!!actualData.title && { name: actualData.title }),
        ...(!!actualData.description && {
          description: actualData.description,
        }),
        ...(!!actualThumbnailPath && {
          thumbnailUrl: `${baseUrl}${actualThumbnailPath}`,
        }),
        ...(!!actualData.uploadDate && { uploadDate: actualData.uploadDate }),
        contentUrl: `https://www.youtube.com/watch?v=${actualData.videoId}`,
        embedUrl: `https://www.youtube.com/embed/${actualData.videoId}`,
      };
    });
  return {
    name: recipe?.headline,
    image,
    video,
    author_name: meta?.author,
    description: meta?.description,
    time: recipe?.time,
    recipeYield: recipe?.persons,
    categories: categories.map((c) => c.name).join(", "),
    ingredients: recipe?.mealComponent?.reduce(
      (acc, cur) => [...acc, ...cur.ingredients],
      []
    ),
    steps: recipe?.mealComponent?.reduce(
      (acc, cur) => [
        ...acc,
        {
          name: cur.name,
          text: cur.cooking,
        },
      ],
      []
    ),
  };
};

export { dataToRecipeData };

const ldJsonRecipe = ({
  name,
  image = [],
  video = [],
  author_name,
  description,
  time,
  // keywords = [],
  categories,
  ingredients = [],
  steps = [],
  recipeYield,
} = {}) =>
  JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "Recipe",
    name,
    image,
    video: video.map((v) => ({
      ...v,
      "@type": "VideoObject",
    })),
    author: {
      "@type": "Person",
      name: author_name,
    },
    description,
    totalTime: `PT${time}M`,
    // keywords: keywords,
    recipeYield: `${recipeYield}`,
    recipeCategory: categories,
    recipeIngredient: ingredients,
    recipeInstructions: steps.map((step) => ({
      "@type": "HowToStep",
      ...step,
    })),
  });

export default ldJsonRecipe;
